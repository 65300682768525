import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "../../Components/Button/Button";
import { createDevice } from "../../Redux/surveillance/action";
import InputBox from "../../Components/InputBox/InputBox";
import NativeSelect from "../../Components/NativeSelect/NativeSelect";

function AddDevice(props) {
  const [formData, setFormData] = useState({
    ipAddress: "",
    modelNo: "",
    serialNo: "",
    type: "NVR",
    locationId: "",
  });

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    let pctNo = [];

    Object.values(props.locations.data).forEach((data) => {
      pctNo.push(data.PCTNo);
    });

    setLocations(pctNo);
  }, [props.locations]);

  return (
    <div className="remaining-body-height-from-header flex-justify-content-center  flex-align-items-center flex-justify-content-space-between">
      <form
        className=" flex-justify-content-center  flex-align-items-center flex-direction-column"
        onChange={(event) => {
          setFormData({
            ...formData,
            [event.target.name]: event.target.value,
          });
        }}
        onSubmit={(event) => {
          event.preventDefault();
          props.createDevice(formData)
        }}
      >
        <div className=" padding-bottom-larger">Add Device</div>

        <NativeSelect
          name="locationId"
          removeResponsive={true}
          label="PCT NO"
          selectClassName="text-transform-capitalize"
          options={[" ", ...locations]}
          //   defaultValue={ }
          fontColor={
            props.theme === "dark" ? "font-color-white" : "font-color-secondary"
          }
        />

        <InputBox
          name="ipAddress"
          type="text"
          removeResponsive={true}
          label="IP ADDRESS"
          className="padding-top-large  "
          value={formData.name}
          fontColor={props.theme === "dark" ? "font-color-white" : ""}
          labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
        />

        <InputBox
          name="modelNo"
          type="text"
          removeResponsive={true}
          label="MODEL NO"
          className="padding-top-large  "
          value={formData.modelNo}
          fontColor={props.theme === "dark" ? "font-color-white" : ""}
          labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
        />

        <InputBox
          name="serialNo"
          type="text"
          removeResponsive={true}
          label="SERIAL NO"
          className="padding-top-large  "
          value={formData.serialNo}
          fontColor={props.theme === "dark" ? "font-color-white" : ""}
          labelFontColor={props.theme === "dark" ? "font-color-white" : ""}
        />

        <NativeSelect
          name="type"
          removeResponsive={true}
          label="DEVICE TYPE"
          selectClassName="text-transform-capitalize"
          options={["NVR"]}
          defaultValue={"NVR"}
          //   disabled = {true}
          fontColor={
            props.theme === "dark" ? "font-color-white" : "font-color-secondary"
          }
        />

        <Button
          boxShadow={false}
          type="submit"
          variant="primary"
          text="Add device"
          className={" "}
          disabled={
            formData?.ipAddress?.length === 0 ||
            formData?.modelNo?.length === 0 ||
            formData?.serialNo?.length === 0 ||
            formData?.type?.length === 0 ||
            formData?.locationId?.length === 0
          }
          data-cy={"upload-button"}
        />
      </form>
    </div>
  );
}

const mapStateToProps = (state) => ({
  surveillance: state.surveillance,
  locations: state.locations,
});

const mapDispatchToProps = function() {
  return {
    createDevice: (data) => createDevice(data),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddDevice);
